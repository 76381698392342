.sidebar {
    width: 25%;
}

.content {
    padding: 5px 20px;
    border-radius: 15px;
}

@media only screen and (max-width: 725px) {
    .sidebar {
        display: none;
    }
}